<template>
  <ModalWrapper v-model="isVisible">
    <CModal :visible="isVisible" backdrop="static" size="lg" @close="isVisible = false">
      <CModalHeader>
        <CModalTitle>{{ $t("upload_file") }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <Dropzone
          id="upload-modal-dropzone"
          :options="dropzoneOptions"
          @success="(original, file) => emit('uploaded', original, file)"
          @error="file => emit('error', file)"
        />
        <div class="text-muted small">{{ $t("supported_files") }}</div>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="isVisible = false">
          {{ $t("close") }}
        </CButton>
      </CModalFooter>
    </CModal>
  </ModalWrapper>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import { computed } from "vue-demi"
import Dropzone from "@/components/Dropzone.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

const i18n = useI18n()

const dropzoneOptions = {
  addRemoveLinks: false,
  acceptedFiles: ".step,.stp,.dxf",
  dictDefaultMessage: i18n.t("upload_modal_body"),
  thumbnailWidth: 150,
}

const props = defineProps<{ modelValue: boolean }>()

const emit = defineEmits(["update:modelValue", "uploaded", "error"])

const isVisible = computed<boolean>({
  get: () => props.modelValue,
  set: (v) => emit("update:modelValue", v),
})
</script>
