<template>
  <div class="customer-multiple-picker-modal">
    <ModalWrapper
      v-model="isVisible"
    >
      <CModal
        :visible="isVisible"
        backdrop="static"
        @close="() => (isVisible = false)"
      >
        <CModalHeader>
          <CModalTitle>{{ $t("pick_pricing") }}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow class="mb-2">
            <CCol :sm="7">
              <CFormInput
                id="pricing-picker-search"
                v-model="search"
                :placeholder="$t('search')"
                aria-label="Search"
              />
            </CCol>
            <CCol :sm="5" class="d-grid">
              <CButton
                color="success"
                @click="() => (showForm = true)"
              >
                <i class="fa fa-plus" />
                {{ $t("add_new_pricing") }}
              </CButton>
            </CCol>
          </CRow>
          <CRow
            v-if="filteredDatas.length > 0"
            style="max-height: 75vh; overflow-y: scroll"
          >
            <CCol>
              <ClickableCard
                v-for="(pricing, index) in filteredDatas"
                :key="index"
                class="mt-2"
                :color="inputSelected === pricing.id ? 'primary' : 'secondary'"
                @click="toggle(getId(pricing))"
              >
                <CRow>
                  <CCol
                    class="d-flex flex-column justify-content-start"
                  >
                    <div v-if="pricing.startup_amount" class="mb-1 small">
                      <span class="me-2">{{ $t("startup_amount") }}:</span>
                      <span>{{ pricing.startup_amount }}</span>
                    </div>
                    <div v-if="pricing.discount_amount" class="mb-1 small">
                      <span class="me-2">{{ $t("discount_amount") }}:</span>
                      <span>{{ pricing.discount_amount }}</span>
                    </div>
                    <div v-if="pricing.shipping_amount" class="small">
                      <span class="me-2">{{ $t("shipping_amount") }}:</span>
                      <span>{{ pricing.shipping_amount }}</span>
                    </div>
                    <div v-if="pricing.tax_rate" class="small">
                      <span class="me-2">{{ $t("tax_rate") }}:</span>
                      <span>{{ pricing.tax_rate }}</span>
                    </div>
                  </CCol>
                </CRow>
              </ClickableCard>
            </CCol>
          </CRow>
          <CRow v-if="filteredDatas.length == 0" class="py-3">
            <CCol>
              <div class="text-muted text-center">
                {{ $t("no_results") }}
              </div>
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter class="justify-content-between">
          <CButton color="secondary" @click="isVisible = false">
            {{ $t("cancel") }}
          </CButton>
          <CButton color="success" @click="save">
            {{ $t("save") }}
          </CButton>
        </CModalFooter>
      </CModal>
    </ModalWrapper>

    <PricingFormModal
      :show="showForm"
      @close="() => (showForm = false)"
      @submit="pricingStore.add"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue-demi'
import { pricingStore } from '@/store'
import { cloneDeep } from 'lodash-es'
import { getId } from "@/interfaces"
import PricingFormModal from "./PricingFormModal.vue"
import ClickableCard from '@/components/ClickableCard.vue'
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

const props = withDefaults(
  defineProps<{
    modelValue?: boolean
    selected?: number
  }>(),
  {
    modelValue: false,
    selected: null
  }
)

const emit = defineEmits(["update:modelValue", "save"])

const showForm = ref(false)
const isVisible = computed({
  get() {
    return props.modelValue && !showForm.value
  },
  // deepcode ignore VueGetterDoesntReturn: no need to return value in setter
  set(v: boolean) {
    emit("update:modelValue", v)
  }
})
watch(isVisible, (v) => {
  /* 
    Have to be cloneDeep to avoid reactivity and change parent values
  */
  if (v == false) {
    inputSelected.value = cloneDeep(props.selected)
    search.value = ""
  }
})

const search = ref("")
const filteredDatas = computed(() => pricingStore.all.filter((pricing) =>
  (pricing.startup_amount || "").toLowerCase().includes(search.value.toLowerCase()) ||
  (pricing.discount_amount || "").toLowerCase().includes(search.value.toLowerCase()) ||
  (pricing.shipping_amount || "").toLowerCase().includes(search.value.toLowerCase()) ||
  (pricing.tax_rate || "").toString().toLowerCase().includes(search.value.toLowerCase())
))

const inputSelected = ref(null)
const toggle = (pricingId: number) => {
  inputSelected.value = !inputSelected.value || inputSelected.value !== pricingId ? pricingId : null
}

const save = () => {
  emit("save", inputSelected.value)
  isVisible.value = false
}
</script>