<template>
  <div class="app d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :lg="5" :md="6" :sm="8">
          <CCard class="p-4">
            <CCardBody v-if="!authStore.resetPasswordRequestSent">
              <CForm>
                <h1 class="text-start">{{ $t("forgot_password") }}</h1>
                <p class="text-muted text-start">
                  {{ $t("forgot_password_input_text") }}
                </p>
                <CForm>
                  <CInputGroup>
                    <CInputGroupText>
                      <CIcon icon="fa-user" />
                    </CInputGroupText>
                    <CFormInput
                      v-model="account"
                      :placeholder="$t('username_or_email')"
                      :invalid="!!errors.account"
                    />
                    <CFormFeedback invalid class="text-start">
                      {{ errors.account }}
                    </CFormFeedback>
                  </CInputGroup>
                </CForm>
                <CRow class="mt-4">
                  <CCol class="text-end d-grid">
                    <CButton block color="primary" @click="submit">
                      <span v-if="!isLoading">{{ $t("reset") }}</span>
                      <CSpinner v-else size="sm" />
                    </CButton>
                  </CCol>
                </CRow>
                <CRow class="mt-3">
                  <CCol class="text-start d-grid">
                    <a class="text-nowrap" :href="login_url">
                      <CIcon icon="fa-arrow-left" class="me-2" />
                      {{ $t("back_to_login") }}
                    </a>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
            <CCardBody v-else>
              <div class="d-flex justify-content-between mb-4 align-items-center">
                <h1>{{ $t("youve_got_mail") }}</h1>
                <CIcon icon="fa-envelope" size="4xl" />
              </div>
              <p class="text-muted">{{ $t("mail_sent_message") }}</p>
              <CRow class="mt-4">
                <CCol class="text-end d-grid">
                  <CButton block color="primary" @click="router.push(login_url)">
                    {{ $t("login") }}
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router"
import { useMeta } from "vue-meta"
import { useI18n } from "vue-i18n"
import { onMounted, ref, computed } from "vue-demi"
import { authStore } from "@/store"

type Error = {
  account?: string
}

const i18n = useI18n()
const route = useRoute()
const router = useRouter()

useMeta({ title: i18n.t("forgot") })

const errors = ref<Error>({})
const isLoading = ref<boolean>(false)
const account = ref<string>([route.query.account].flat()[0] || "")

const login_url = computed(() => {
  const url = `${authStore.manufacturerNicknamePrefixUrl}/login`
  return account.value ? `${url}?username=${account.value}` : url
})

const submit = async (event: MouseEvent) => {
  if (isLoading.value) return
  event.preventDefault()

  errors.value = {}

  if (!account.value)
    errors.value.account = i18n.t("username_or_email_is_required")

  if (Object.keys(errors.value).length === 0) {
    isLoading.value = true
    await authStore.reset(account.value)
    isLoading.value = false
  }
}

onMounted(() => {
  authStore.resetPasswordRequestSent = false
})
</script>

<style scoped>
.app {
  background-image: url("/img/bg/pattern.svg");
  background-size: cover;
}
</style>
