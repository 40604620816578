<template>
  <CCard
    class="cardHover cursor-pointer customer-info-card"
    :class="[`text-${props.color}`]"
  >
    <CCardBody
      @click="emit('click')"
    >
      <CRow>
        <CCol>
          <h5 class="pt-1">
            <i class="mr-1 fa fa-building" />
            {{ props.data.name }}
          </h5>
          <div class="small">#{{ props.data.company_number }}</div>
        </CCol>
        <CCol
          class="d-flex flex-column justify-content-end align-items-end"
        >
          <div v-if="props.data.phone" class="mb-1 small">
            {{ props.data.phone }}
            <i class="ms-2 fa fa-phone" />
          </div>
          <div v-if="props.data.email" class="mb-1 small">
            {{ props.data.email }}
            <i class="ms-2 fa fa-envelope" />
          </div>
          <div v-if="props.data.domain" class="small">
            {{ props.data.domain }}
            <i class="ms-2 fa fa-globe" />
          </div>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script lang="ts" setup>
import type { Customer } from '@/interfaces';

const props = withDefaults(
  defineProps<{
    data: Customer
    color?: string
  }>(),
  {
    color: "secondary",
  }
)

const emit = defineEmits(["click"])
</script>

<style lang="scss" scoped>
.cardHover {
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2);
  }
}
.customer-info-card {
  &.text-primary {
    border-color: var(--primary)
  }
}
</style>
