<template>
  <div class="wrapped-with-dullsranec">
    <ModalWrapper v-model="isVisible">
      <CModal
        size="lg"
        :visible="isVisible"
        backdrop="static"
        @close="() => (isVisible = false)"
      >
        <CModalHeader>
          <div class="flex">
            <CModalTitle>{{ props.title }}</CModalTitle>
            <div class="small text-muted">{{ props.subtitle }}</div>
          </div>
        </CModalHeader>
        <CModalBody class="d-flex flex-column">
          <div class="mb-2">
            <CButton style="float: right" color="success" @click="changeFormVisibility()">
              <b>+</b>
              {{ $t("add_new_setting") }}
            </CButton>
          </div>
          <div v-if="props.data.length > 0">
            <CCard v-for="(setting, i) in props.data" :key="i" class="mb-2">
              <CCardHeader
                class="justify-content-between d-flex bg-light cursor-pointer"
                @click="visibleCollapseIndex = visibleCollapseIndex == i ? null : i"
              >
                <div>
                  <CBadge
                    v-for="(material, im) in setting.materials"
                    :key="im"
                    class="badgeRight"
                    color="success"
                  >
                    {{ material.name }}
                  </CBadge>
                </div>
                <i
                  class="fa floatRight"
                  :class="{
                    'fa-chevron-down': visibleCollapseIndex != i,
                    'fa-chevron-up': visibleCollapseIndex == i,
                  }"
                />
              </CCardHeader>
              <CCollapse :visible="i == visibleCollapseIndex">
                <CCardBody class="d-flex justify-content-between">
                  <div>
                    <p v-if="setting.name != null" class="d-flex flex-column">
                      <strong>{{ setting.name }}</strong>
                      <span class="col small text-muted">{{ setting.description }}</span>
                    </p>
                    <div>
                      <strong>{{ $t("startup_time") }}:</strong>
                      {{ setting.startup_time }}
                    </div>
                    <div>
                      <strong>{{ $t("cycle_time") }}:</strong>
                      {{ setting.cycle_time }}
                    </div>
                  </div>
                  <div class="d-flex align-items-center flex-column">
                    <CButton
                      class="w-100"
                      color="primary" 
                      @click="changeFormVisibility(true, setting)"
                    >
                      {{ $t("edit") }}
                    </CButton>
                    <CButton
                      class="w-100 mt-2"
                      color="danger" 
                      @click="remove(setting)"
                    >
                      {{ $t("remove") }}
                    </CButton>
                  </div>
                </CCardBody>
              </CCollapse>
            </CCard>
          </div>
          <CCard v-else>
            <CCardHeader class="justify-content-between d-flex bg-light" />
            <CCardBody>
              <p class="text-center">{{ $t("no_data") }}</p>
            </CCardBody>
          </CCard>
        </CModalBody>
        <CModalFooter class="justify-content-start">
          <CButton color="secondary" @click="() => (isVisible = false)">
            {{ $t("close") }}
          </CButton>
        </CModalFooter>
      </CModal>
    </ModalWrapper>

    <ModalWrapper v-model="formVisible">
      <CModal
        size="xl"
        :visible="formVisible"
        backdrop="static"
        @close="changeFormVisibility(false)"
      >
        <CModalHeader>
          <CModalTitle>
            {{ edit.active ? $t("edit") : $t("add_new_setting") }}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <DynamicFormWrapper :form="form" @submit="onSubmit" @change="formChanged" />
        </CModalBody>
        <CModalFooter class="justify-content-between">
          <div class="d-flex flex-grow-1">
            <CButton class="me-4" color="secondary" @click="onCancel">
              {{ $t("cancel") }}
            </CButton>
          </div>
          <CButton
            v-if="edit.active"
            class="me-4"
            color="danger"
            @click="remove(edit.data)"
          >
            {{ $t("remove") }}
          </CButton>
          <CButton color="success" type="submit" :form="form.id">
            {{ edit.active ? $t("save") : $t("add") }}
          </CButton>
        </CModalFooter>
      </CModal>
    </ModalWrapper>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, ref, computed } from "vue-demi"
import { useI18n } from "vue-i18n"
import {
  isEqual,
  cloneDeep,
  pick,
  keys,
  omitBy,
  isNil,
  isObject,
  toArray,
} from "lodash-es"
import sweetalert from "sweetalert2"
import type { Setting } from "@/interfaces"
import { materialStore } from "@/store"
import DynamicFormWrapper from "@/components/dynamicForm/DynamicFormWrapper.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

const i18n = useI18n()

const props = withDefaults(
  defineProps<{
    modelValue?: boolean
    title?: string
    subtitle?: string
    data?: Setting[]
  }>(),
  {
    modelValue: false,
    title: "",
    subtitle: "",
    data: () => [],
  }
)

const emit = defineEmits(["update:modelValue", "add", "update", "remove"])

const formValues = ref<any>({})
const formChanged = (values: any) => {
  formValues.value = omitBy(values, isNil)
}

const visibleCollapseIndex = ref(0)
const isVisible = computed<boolean>({
  get: () => props.modelValue,
  set: (v) => {
    visibleCollapseIndex.value = 0
    emit("update:modelValue", v)
  },
})

const edit: any = ref({
  active: false,
  data: null,
  values: {},
})
const form = computed(() => ({
  id: "setting-modal-form",
  fields: {
    id: {
      type: "hidden",
      defaultValue: edit.value.values.id,
    },
    materials: {
      type: "multiselect",
      label: i18n.t("raw_materials"),
      placeholder: i18n.t("select_or_start_typing"),
      defaultValue: edit.value.values.materials,
      options: materialStore.all,
      selectOptions: {
        multiple: true,
        trackBy: "id",
        label: "name",
        hideSelected: true,
        searchable: true,
        selectLabel: i18n.t("press_enter_select"),
        selectedLabel: i18n.t("selected"),
        deselectLabel: i18n.t("press_enter_remove"),
        closeOnSelect: false,
      },
    },
    name: {
      type: "text",
      label: i18n.t("name"),
      placeholder: "",
      defaultValue: edit.value.values.name,
    },
    description: {
      type: "text",
      label: i18n.t("description"),
      placeholder: "",
      defaultValue: edit.value.values.description,
    },
    startup_time: {
      type: "equation",
      label: i18n.t("startup_time"),
      placeholder: "",
      validations: ["required"],
      defaultValue: edit.value.values.startup_time,
      variables: [
        "width",
        "height",
        "length",
        "area",
        "volume",
        "weight",
        "quantity",
        "pattern.origin.x",
        "pattern.origin.y",
        "pattern.width",
        "pattern.height",
        "pattern.thickness",
        "pattern.contour.boundary",
        "pattern.contour.area",
        "pattern.holes",
        "pattern.other",
        "pattern.area",
        "pattern.boundary",
        "bends",
        "cutting_time",
      ],
    },
    cycle_time: {
      type: "equation",
      label: i18n.t("cycle_time"),
      placeholder: "",
      defaultValue: edit.value.values.cycle_time,
      validations: ["required"],
      variables: [
        "width",
        "height",
        "length",
        "area",
        "volume",
        "weight",
        "quantity",
        "pattern.origin.x",
        "pattern.origin.y",
        "pattern.width",
        "pattern.height",
        "pattern.thickness",
        "pattern.contour.boundary",
        "pattern.contour.area",
        "pattern.holes",
        "pattern.other",
        "pattern.area",
        "pattern.boundary",
        "bends",
        "cutting_time",
      ],
    },
    cutting_feeds: {
      type: "table",
      label: "Feeds",
      defaultValue: edit.value.values.cutting_feeds,
      tableOptions: {
        columns: [
          "thickness",
          "pierce_time",
          "area_small",
          "area_medium",
          "area_large",
          "feed_small",
          "feed_medium",
          "feed_large",
        ],
        options: {
          skin: "table table-hover",
          sortIcon: {
            base: "fa fa-lg",
            up: "fa-sort-asc",
            down: "fa-sort-desc",
            is: "fa-sort",
          },
          headings: {
            thickness: "Thickness [mm]",
            pierce_time: "Pierce time [s]",
            area_small: "Small [mm²]",
            area_medium: "Medium [mm²]",
            area_large: "Large [mm²]",
            feed_small: "Small [mm/min]",
            feed_medium: "Medium [mm/min]",
            feed_large: "Large [mm/min]",
          },
        },
      },
    },
  },
  valueProcessor: values => {
    delete values.isTrusted
    delete values._vts
    return values
  },
}))

const formVisible = ref(false)
const changeFormVisibility = (show = true, data = null) => {
  formVisible.value = show
  isVisible.value = !show
  if (!show) return

  edit.value.active = !!data
  edit.value.data = data
  formValues.value = edit.value.values = {}
  if (edit.value.active) {
    edit.value.values = cloneDeep(omitBy(pick(data, keys(form.value.fields)), isNil))
    if (isObject(edit.value.values.materials))
      edit.value.values.materials = toArray(edit.value.values.materials).sort()
    if (isObject(edit.value.values.cutting_feeds))
      edit.value.values.cutting_feeds = toArray(edit.value.values.cutting_feeds).sort()
    formValues.value = cloneDeep(edit.value.values)
  }
}

const onCancel = () => {
  if (!edit.value.active || isEqual(formValues.value, edit.value.values)) {
    changeFormVisibility(false)
    return
  }
  sweetalert
    .fire({
      title: i18n.t("unsaved_changes"),
      text: i18n.t("unsaved_changes_body"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: i18n.t("yes_please"),
      cancelButtonText: i18n.t("no_thanks"),
    })
    .then(result => {
      if (result.isConfirmed) {
        if (Object.prototype.hasOwnProperty.call(formValues.value, "isTrusted"))
          delete formValues.value.isTrusted
        emit("update", formValues.value)
        console.log("confirmed")
      }
    })
    .then(() => {
      changeFormVisibility(false)
    })
    .catch()
}
const onSubmit = (v: any) => {
  changeFormVisibility(false)
  delete v.isTrusted

  emit(!v.id ? "add" : "update", v)
}

const remove = (setting: Setting) => {
  if (!setting) return

  sweetalert
    .fire({
      title: i18n.t("remove"),
      text: i18n.t("setting_remove_sure"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: i18n.t("yes_sure"),
      cancelButtonText: i18n.t("cancel"),
    })
    .then(result => {
      if (result.isConfirmed) {
        visibleCollapseIndex.value = null
        if (edit.value.active) changeFormVisibility(false)
        nextTick(() => emit("remove", setting))
      }
    })
    .catch()
}
</script>

<style lang="scss">
.wrapped-with-dullsranec {
  legend {
    display: block;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 1.2em;
  }
  .cardTop {
    margin-top: 35px;
  }
  .badgeRight {
    margin-right: 5px;
  }
  .inlineBlock {
    margin: 0px 10px 10px 0;
    padding: 10px;
    width: 110px;
  }
}
</style>
