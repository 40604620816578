import { defineStore } from "pinia"
import axios, { AxiosError } from "axios"
import { authErrorReasons, type RegisterInterface } from "@/interfaces"
import { LOCALSTORAGE_GENERAL_KEY } from "@/constants"
import { manufacturerStore, userStore, organizationStore, resetStore, requestStore, toastStore } from "."
import { clearCache } from "@/libraries/helpers"
import { i18n } from "@/plugins/i18n"
import { isJwtExpired } from "@/libraries/helpers"

export default defineStore("auth", {
  state: () => ({
    isRegistered: false,
    resetPasswordRequestSent: false,
    isAuthenticated: !!localStorage.access_token,
    isRefreshingToken: false,
    isLoginAs: !!localStorage.__smartpart_admin_data__,
  }),
  getters: {
    authenticatedUser: () =>
      userStore.mappedCurrent
        ? {
            ...userStore.mappedCurrent,
            organization: organizationStore.mappedCurrent,
          }
        : null,
    manufacturerNicknamePrefixUrl: () =>
      manufacturerStore.manufacturerNickname ? `/${manufacturerStore.manufacturerNickname}` : "",
    loginPath() {
      return `${this.manufacturerNicknamePrefixUrl}/login`
    },
  },
  actions: {
    login({ username = "", password = "" }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/v1/auth/jwt/token", { username, password }, { headers: { "Content-Type": "application/json" } })
          .then(({ data }) => {
            localStorage.access_token = data.access_token
            localStorage.refresh_token = data.refresh_token
            this.isAuthenticated = true
            axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`
            resolve(data)
          })
          .catch(e => {
            this.logout(authErrorReasons.WRONG_CREDENTIALS)
            reject(e)
          })
      })
    },
    refreshToken() {
      return new Promise((resolve, reject) => {
        // Don't refresh token for login as
        // Don't refresh token if refresh token is expired
        if (this.isLoginAs || isJwtExpired(localStorage.refresh_token)) {
          this.logout(authErrorReasons.TOKEN_EXPIRED)
          reject()
          return
        }

        this.isRefreshingToken = true
        axios
          .post(
            "/v1/auth/jwt/token/refresh",
            { refresh_token: localStorage.refresh_token },
            { headers: { "Content-Type": "application/json" } }
          )
          .then(({ data }) => {
            localStorage.access_token = data.access_token
            this.isAuthenticated = true
            axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`
            resolve(data)
          })
          .catch(() => this.logout(authErrorReasons.TOKEN_EXPIRED))
          .finally(() => (this.isRefreshingToken = false))
      })
    },
    loginAs(userId: number) {
      return new Promise((resolve, reject) => {
        const user = userStore.mappedData.find(u => u.id === userId)
        if (!user) {
          const message = i18n.t("user_not_found", { userId })
          toastStore.toasts.push({ color: "danger", message })
          return reject(message)
        }
        if (user.availableManufacturers.length === 0) {
          const message = i18n.t("user_has_no_manufacturer", { user: user.username })
          toastStore.toasts.push({ color: "danger", message })
          return reject(message)
        }
        const manufacturer = user.availableManufacturers?.length > 0 ? user.availableManufacturers[0] : null
        Promise.all([
          axios.post("/v1/auth/jwt/user-token", { user_id: userId }),
          axios.get(`/v1/manufacturers/${manufacturer}`),
        ])
          .then(async ([{ data }, _]) => {
            localStorage.setItem(
              "__smartpart_admin_data__",
              JSON.stringify({
                access_token: localStorage.getItem("access_token"),
                refresh_token: localStorage.getItem("refresh_token"),
                manufacturer: localStorage.getItem("manufacturer"),
              })
            )
            this.isLoginAs = true

            localStorage.access_token = data.access_token
            localStorage.removeItem("refresh_token")

            if (!localStorage.manufacturer) localStorage.removeItem("manufacturer")

            if (manufacturer) {
              localStorage.manufacturer = user.availableManufacturers[0]
              manufacturerStore.updateLogoUrlByNickname(localStorage.manufacturer)
            }
            if (user.availableManufacturers?.length > 0) {
              localStorage.manufacturer = user.availableManufacturers[0]
              manufacturerStore.updateLogoUrlByNickname(localStorage.manufacturer)
            }

            axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`

            // reset all data
            localStorage.removeItem(LOCALSTORAGE_GENERAL_KEY)
            resetStore()
            await clearCache()
            location.replace("/")
            resolve(data)
          })
          .catch(error => {
            const url = error?.config?.url
            toastStore.toasts.push({
              color: "danger",
              message: !url
                ? error
                : url.includes("manufacturers")
                ? i18n.t("manufacturer_doesnt_exist", { manufacturer })
                : i18n.t("failed_login_as", { user: user.username }),
            })
            reject(error)
          })
      })
    },
    register(user: RegisterInterface) {
      return new Promise((resolve, reject) => {
        axios
          .post("/v1/users/register", user)
          .then(response => {
            this.isRegistered = true
            resolve(response.data)
          })
          .catch(reject)
      })
    },
    reset(account: String) {
      return new Promise((resolve, reject) => {
        axios
          .post("/v1/users/reset", { account })
          .then(response => {
            this.resetPasswordRequestSent = true
            resolve(response.data)
          })
          .catch(reject)
      })
    },
    async logout(reason = null) {
      try {
        requestStore.cancelRunningFetches()

        // Invalidate token and cookie if token not expired yet
        if (![authErrorReasons.TOKEN_EXPIRED, authErrorReasons.WRONG_CREDENTIALS].includes(reason))
          await axios.get("/v1/auth/jwt/logout")

        if (!this.isLoginAs) {
          localStorage.removeItem("access_token")
          localStorage.removeItem("refresh_token")
          localStorage.removeItem("showSidebar")
          this.isAuthenticated = false
          delete axios.defaults.headers.common.Authorization

          if (reason === authErrorReasons.TOKEN_EXPIRED) {
            localStorage.error = i18n.t("session_expired_error")
          }
          if (reason === authErrorReasons.WRONG_CREDENTIALS) {
            localStorage.error = i18n.t("wrong_username_or_password")
          }
        } else {
          const adminData = JSON.parse(localStorage.getItem("__smartpart_admin_data__"))

          localStorage.access_token = adminData.access_token
          localStorage.refresh_token = adminData.refresh_token
          if (adminData.manufacturer) {
            localStorage.manufacturer = adminData.manufacturer
            manufacturerStore.updateLogoUrlByNickname(localStorage.manufacturer)
          } else {
            localStorage.removeItem("manufacturer")
          }
          axios.defaults.headers.common.Authorization = `Bearer ${adminData.access_token}`

          localStorage.removeItem("__smartpart_admin_data__")
          localStorage.removeItem(LOCALSTORAGE_GENERAL_KEY)

          if (reason === authErrorReasons.TOKEN_EXPIRED) {
            localStorage.error = i18n.t("login_as_session_expired_error")
          }

          // refresh admin token to get new session cookie
          this.isLoginAs = false
          await this.refreshToken().catch()
        }

        // reset all data
        localStorage.removeItem("manufacturer-logo")
        resetStore()
        await clearCache()

        location.replace(!this.isLoginAs ? this.loginPath : "/")
      } catch (error: AxiosError | any) {
        if (error?.response?.data?.message) {
          toastStore.toasts.push({
            color: "danger",
            message: error?.response?.data?.message,
          })
        }
      }
    },
  },
})
