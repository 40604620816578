<template>
  <i
    v-if="iconIsFontAwesomeType"
    :class="['fa-icon', props.type ? props.type : 'fa-solid', icon, props.size]"
  />

  <BaseCIcon
    v-else-if="icon"
    :icon="icon"
    :size="props.size"
    :custom-classes="props.customClasses"
  />
</template>

<script lang="ts" setup>
import { computed } from "vue-demi"
import { CIcon as BaseCIcon } from "@coreui/icons-vue"
import { asyncComputed } from "@vueuse/core"
import { dynamicallyImportIcon } from "@/libraries/helpers"

const props = withDefaults(defineProps<{
    icon: string
    size?: string
    customClasses?: string | string[] | object
    type?: string
  }>(),
  {
    customClasses: "",
    type: null
  }
)

const icon = asyncComputed<string | string[]>(async () => {
  if (props.icon.startsWith("fa")) return props.icon
    return await dynamicallyImportIcon(props.icon)
  }, ""
)

const iconIsFontAwesomeType = computed(
  () => typeof icon.value === "string" && icon.value.startsWith("fa")
)
</script>
