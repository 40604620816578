<template>
  <div class="animated fadeIn">
    <VTables
      :title="$t('logs')"
      :subtitle="$t('request_logs_of_api')"
      :columns="tableColumns"
      :make-form="() => ({})"
      :table-options="tableOptions"
      :data="logStore.all"
      :row-click="() => {}"
    >
      <template #response_size="{ row }">{{ row.response_size.toFixed(0) }} B</template>
      <template #latency="{ row }">{{ (row.latency * 1000).toFixed(2) }} ms</template>
    </VTables>
  </div>
</template>

<script lang="ts" setup>
import type { VTableOptions } from "@/components/VTables.vue"
import { useI18n } from "vue-i18n"
import { useMeta } from "vue-meta"
import { onMounted } from "vue-demi"
import type { Log } from "@/interfaces"
import { logStore } from "@/store"
import VTables from "@/components/VTables.vue"

const i18n = useI18n()

useMeta({ title: i18n.t("logs") })

const tableColumns = [
  "start_time",
  "method",
  "status",
  "response_size",
  "latency",
  "user_agent",
  "resource",
]

const tableOptions: VTableOptions = {
  headings: {
    start_time: i18n.t("start_time"),
    method: i18n.t("method"),
    response_size: i18n.t("response_size"),
    latency: i18n.t("latency"),
    user_agent: i18n.t("user_agent"),
    resource: i18n.t("resource"),
    status: i18n.t("status"),
  },
  sortable: [
    "start_time",
    "method",
    "status",
    "response_size",
    "latency",
    "user_agent",
    "resource",
    "status",
  ],
  filterable: [
    "start_time",
    "method",
    "status",
    "response_size",
    "latency",
    "user_agent",
    "resource",
    "status",
  ],
  filterAlgorithm: {
    latency(row: Log, query: string) {
      const str = ((row.latency || 0) * 1000).toFixed(2).toLowerCase()
      return str.includes(query.toLowerCase())
    },
  },
  customFilters: [
    {
      name: "all",
      callback(row: Log, query: string) {
        return [
          row.start_time || "",
          row.method || "",
          row.status || "",
          row.response_size || "",
          ((row.latency || 0) * 1000).toFixed(2),
          row.user_agent || "",
          row.resource || "",
        ]
          .join("###")
          .toLowerCase()
          .includes(query.toLowerCase())
      },
    },
  ],
}

onMounted(() => {
  logStore.fetchAll()
})
</script>
