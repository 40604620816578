<template>
  <div class="animated fadeIn wrapped-with-csiprrkatc">
    <VTables
      ref="table"
      :title="$t('nestings')"
      :subtitle="$t('nestings_queue')"
      :columns="tableColumns"
      :make-form="() => ({})"
      :table-options="tableOptions"
      :data="nestingStore.all"
    >
      <template #id="{ row }">
        <div class="small text-muted">{{ row.id }}</div>
      </template>

      <template #stock="{ row }">
        {{ row.stock.name }}
        <div class="small text-muted">{{ row.stock.id }}</div>
      </template>

      <template #status="{ row }">
        <CBadge v-if="row.status == 'SUBMITTED'" color="primary">
          {{ row.status }}
        </CBadge>
        <CBadge v-if="row.status == 'COMPUTING'" color="secondary">
          {{ row.status }}
        </CBadge>
        <CBadge v-if="row.status == 'EXPORTING'" color="warning">
          {{ row.status }}
        </CBadge>
        <CBadge v-if="row.status == 'COMPLETED'" color="success">
          {{ row.status }}
        </CBadge>
        <CBadge v-if="row.status == 'TIMEOUT'" color="danger">
          {{ row.status }}
        </CBadge>
        <CBadge v-if="row.status == 'FAILED'" color="danger">
          {{ row.status }}
        </CBadge>
      </template>

      <template #batches="{ row }">
        <div style="max-width: 300px">
          <span v-for="batch in row.batches" :key="batch.id" class="me-1">
            <CBadge color="primary">{{ batch.id }}</CBadge>
          </span>
        </div>
      </template>

      <template #plans="{ row }">
        <span v-for="plan in row.plans" :key="plan.id" class="me-1">
          <CBadge color="primary">{{ plan.id }}</CBadge>
        </span>
      </template>

      <template #actions="{ row }">
        <Popover
          v-if="row && row.plans.length > 0"
          :text="$t('download_nesting_zipped', { ext: '.dxf' })"
        >
          <CButton
            class="btn-space btn-sm"
            color="primary"
            @click.stop="exportPlans(row.id, row.plans)"
          >
            <strong>ZIP</strong>
          </CButton>
        </Popover>
      </template>
    </VTables>
  </div>
</template>

<script lang="ts" setup>
/**
 * TODO: Refactor this once the team wants to add Nestings page
 */

import { useMeta } from "vue-meta"
import { useI18n } from "vue-i18n"
import { onMounted } from "vue-demi"
import type { Nesting } from "@/interfaces"
import { manufacturerStore, nestingStore } from "@/store"
import { dateFilterAlgorithm, dateToFilterString } from "@/libraries/helpers"
import VTables, { type VTableOptions } from "@/components/VTables.vue"
import Popover from "@/components/Popover.vue"

const i18n = useI18n()
useMeta({ title: i18n.t("nestings") })

const tableColumns = [
  "date__created",
  "date__updated",
  "stock",
  "status",
  "timeout",
  "batches",
  "plans",
  "actions",
]
const tableOptions: VTableOptions = {
  headings: {
    date__created: i18n.t("created"),
    date__updated: i18n.t("updated"),
    stock: i18n.t("stock"),
    status: i18n.t("status"),
    timeout: i18n.t("timeout"),
    batches: i18n.t("batches"),
    plans: i18n.t("plans"),
  },
  sortable: [
    "date__created",
    "date__updated",
    "stock",
    "status",
    "timeout",
    "batches",
    "plans",
  ],
  filterAlgorithm: {
    date__created: (row: Nesting, query: string) =>
      dateFilterAlgorithm(row, query, "created"),
    date__updated: (row: Nesting, query: string) =>
      dateFilterAlgorithm(row, query, "updated"),
  },
  customFilters: [
    {
      name: "all",
      callback(row: Nesting, query: string) {
        return [
          dateToFilterString(row, "created"),
          dateToFilterString(row, "updated"),
          row.stock || "",
          row.status || "",
          row.timeout || "",
          row.batches || "",
          row.plans || "",
        ]
          .join("###")
          .toLowerCase()
          .includes(query.toLowerCase())
      },
    },
  ],
}

function dateToString(date) {
  if (date) {
    return new Date(date).toISOString().replace(/\..+/, "")
  }
  return ""
}

// Get stock by it's id
function getStock(id) {
  let index = manufacturerStore.sheets.findIndex(x => x.id == id)
  if (index >= 0) {
    return manufacturerStore.sheets[index]
  }

  index = manufacturerStore.profiles.findIndex(x => x.id == id)
  if (index >= 0) {
    return manufacturerStore.profiles[index]
  }

  return {}
}

// Get material by it's id
function getMaterial(id) {
  const index = manufacturerStore.materials.findIndex(x => x.id == id)
  if (index >= 0) {
    return manufacturerStore.materials[index]
  }

  return {}
}

// Get machine by it's id
function getMachine(id) {
  const index = manufacturerStore.machines.findIndex(x => x.id == id)
  if (index >= 0) {
    return manufacturerStore.machines[index]
  }

  return {}
}

// Donwload Plan DXF
function exportPlans(id, plans) {
  console.log("export :", id, plans)
  // var zip = new JSZip()
  // var nesting_files = zip.folder("id")

  // var fileUrls = [];
  // var fileNames = [];
  // for (var i = 0; i < plans.length; i++) {
  //     fileUrls.push('/v1/plans/' + plans[i] + '/exports')
  //     fileNames.push(plans[i] + '.dxf')
  // }

  // this.$store.dispatch('API_GET_ALL', fileUrls)
  // .then(resp => {
  //   for (var i = 0; i < resp.length; i++) {
  //     nesting_files.file(fileNames[i], resp[i].data)
  //   }

  //   return nesting_files
  // })
  // .then(archive => archive.generateAsync({
  //   type: "blob"
  // }))
  // .then(blob => {
  //   const url = window.URL.createObjectURL(new Blob([blob]))
  //   const link = document.createElement('a')
  //   link.href = url
  //   link.setAttribute('download', id + ".zip") //or any other extension
  //   document.body.appendChild(link)
  //   link.click()
  // })
  // .catch(error => {
  //   this.$notify({
  //     type: "error",
  //     title: i18n.t('failed'),
  //     text: i18n.t('failed_alert')
  //   })
  // })
}

onMounted(() => {
  nestingStore.fetchAll()
})
</script>

<style lang="scss">
.wrapped-with-csiprrkatc {
  .dateInput {
    width: 300px !important;
    max-width: 300px !important;
  }
}
</style>
