<template>
  <div class="animated fadeIn">
    <VTables
      ref="table"
      :title="$t('pricings')"
      :subtitle="$t('all') + ' ' + $t('pricings')"
      :add-text="$t('add_pricing')"
      :edit-text="$t('edit_pricing')"
      :columns="tableColumns"
      :action-delete="true"
      :action-edit="true"
      :show-add-button="true"
      :make-form="makeForm"
      :table-options="tableOptions"
      :data="pricingStore.mappedData"
      :add="pricingStore.add"
      :update="pricingStore.update"
      :remove="({ id }) => pricingStore.remove(id)"
      :default-sort="{ column: 'date__created', ascending: false }"
    >
      <template #created_by="{ row }">
        {{ row.created_by?.username }}
      </template>
      <template #customers="{ row }">
        <Popover
          :text="$t('apply_price_to_customers')"
          justify="left"
        >
          <CButton
            class="btn-space btn-sm"
            color="secondary"
            @click.stop="showCustomerPickerModal(row.id, row.customers)"
          >
            <i class="fa fa-users" />
          </CButton>
        </Popover>
      </template>
    </VTables>

    <CustomerMultiplePickerModal
      v-model="customerPicker.show"
      :selected="customerPicker.selected"
      @save="applyPricingToCustomers"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue-demi"
import { customerStore, pricingStore } from "@/store"
import { useMeta } from "vue-meta"
import { useI18n } from "vue-i18n"
import { chunk, difference } from 'lodash-es'
import { dateFilterAlgorithm, dateToFilterString } from "@/libraries/helpers"
import { getId, type Pricing, type MappedPricing } from '@/interfaces'
import { PRICING_EQUATION_VARIABLES } from "@/constants"
import VTables, { type VTableOptions } from "@/components/VTables.vue"
import Popover from "@/components/Popover.vue"
import CustomerMultiplePickerModal from '@/components/modals/customer/CustomerMultiplePickerModal.vue'

const i18n = useI18n()
useMeta({ title: i18n.t("pricings") })

const tableColumns = [
  "date__created",
  "created_by",
  "startup_amount",
  "discount_amount",
  "shipping_amount",
  "tax_rate",
  "customers",
  "actions"
]
const tableOptions: VTableOptions = {
  sortable: [
    "startup_amount",
    "discount_amount",
    "shipping_amount",
    "tax_rate",
    "created_by",
    "date__created",
  ],
  headings: {
    startup_amount: i18n.t("startup_amount"),
    discount_amount: i18n.t("discount_amount"),
    shipping_amount: i18n.t("shipping_amount"),
    tax_rate: i18n.t("tax_rate"),
    created_by: i18n.t("created_by"),
    date__created: i18n.t("created"),
  },
  filterAlgorithm: {
    date__created: (row: Pricing, query: string) => dateFilterAlgorithm(row, query, "created"),
  },
  customFilters: [
    {
      name: "all",
      callback(row: MappedPricing, query: string) {
        return [
          row.startup_amount || "",
          row.discount_amount || "",
          row.shipping_amount || "",
          row.tax_rate || "",
          row.created_by?.username || "",
          dateToFilterString(row, "created"),
        ]
          .join("###")
          .toLowerCase()
          .includes(query.toLowerCase())
      },
    },
  ],
}
const makeForm = (data?: Pricing) => ({
  id: "pricings-page-form",
  fields: {
    id: {
      type: "hidden",
      defaultValue: data?.id,
    },
    startup_amount: {
      type: "equation",
      label: i18n.t("startup_amount"),
      defaultValue: data?.startup_amount,
      validations: [],
      variables: PRICING_EQUATION_VARIABLES
    },
    discount_amount: {
      type: "equation",
      label: i18n.t("discount_amount"),
      defaultValue: data?.discount_amount,
      validations: [],
      variables: PRICING_EQUATION_VARIABLES
    },
    shipping_amount: {
      type: "equation",
      label: i18n.t("shipping_amount"),
      defaultValue: data?.shipping_amount,
      validations: [],
      variables: PRICING_EQUATION_VARIABLES
    },
    tax_rate: {
      type: "number",
      label: i18n.t("tax_rate"),
      defaultValue: data?.tax_rate,
      validations: [],
    },
  },
})

const selectedPrice = ref(null)
const customerPicker = ref({
  show: false,
  selected: []
})

const showCustomerPickerModal = (id: number, customers = []) => {
  selectedPrice.value = id
  customerPicker.value.show = true
  customerPicker.value.selected = customers.map(getId)
}
const applyPricingToCustomers = async (customerIds: number[]) => {
  const removedCustomers = difference(customerPicker.value.selected, customerIds)
  const newlySelectedCustomers = difference(customerIds, customerPicker.value.selected)

  const updateCustomerRequests = [
    ...removedCustomers.map(async id => await customerStore.update({ id, pricing: null })),
    ...newlySelectedCustomers.map(async id => await customerStore.update({ id, pricing: selectedPrice.value })),
  ]
  const chunks = chunk(updateCustomerRequests, 8)

  for (const chunk of chunks) {
    await Promise.allSettled(chunk)
  }
}

onMounted(() => {
  pricingStore.fetchAll()
  customerStore.fetchAll()
})
</script>
