<template>
  <div class="animated fadeIn">
    <VTables
      ref="table"
      :title="$t('profiles')"
      :subtitle="`${$t('all')} ${$t('profiles')}`"
      :add-text="$t('add_profile')"
      :edit-text="$t('edit_profile')"
      :columns="tableColumns"
      :action-delete="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :action-edit="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :action-viewer="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :show-add-button="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin !== true"
      :merge-options="mergeOptions"
      :make-form="makeForm"
      :show-merge-options="true"
      :table-options="tableOptions"
      :data="profileStore.mappedData"
      :add="profileStore.add"
      :update="profileStore.update"
      :remove="({ id }) => profileStore.remove(id)"
      :default-sort="{ column: 'name', ascending: true }"
      @action-clicked="({ action, data }) => action === 'viewer' && openViewer(data)"
      @merge-done="profileStore.modifyData"
    />
    <ProfilePreviewModal v-model="showViewer" :profile="viewerData" />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import { useMeta } from "vue-meta"
import { onMounted, watchEffect, ref, computed } from "vue-demi"
import type { Profile, MergerSchema, ProcessedProfile } from "@/interfaces"
import { profileStore, materialStore, authStore, requestStore } from "@/store"
import { convertUnit, booleanCustomSorting, generateFilterAlgorithm, getListColumns } from "@/libraries/helpers"
import VTables, { type VTableOptions } from "@/components/VTables.vue"
import ProfilePreviewModal from "@/components/modals/ProfilePreviewModal.vue"

const i18n = useI18n()
useMeta({ title: i18n.t("profiles") })

const showViewer = ref(false)
const viewerData = ref(null)
const openViewer = (row: Profile) => {
  showViewer.value = true
  viewerData.value = row
}
watchEffect(() => {
  if (!showViewer) viewerData.value = null
})

const tableColumns = [
  "name",
  "material",
  "thickness",
  "length",
  "cost",
  "boolean__is_available",
  "boolean__is_in_stock",
  "type",
  "stock",
  "actions",
]
const tableOptions: VTableOptions = {
  headings: {
    name: i18n.t("name"),
    actions: i18n.t("actions"),
    material: i18n.t("material"),
    thickness: i18n.t("thickness_mm"),
    length: i18n.t("length_mm"),
    cost: i18n.t("cost_e_m_unit"),
    boolean__is_available: i18n.t("is_available"),
    boolean__is_in_stock: i18n.t("in_stock"),
    type: i18n.t("shape"),
    stock: i18n.t("stock"),
  },
  sortable: tableColumns.filter(column => column !== "actions"),
  get filterable() {
    return this.sortable
  },
  customSorting: {
    boolean__is_available: (ascending: boolean) => (a: Profile, b: Profile) =>
      booleanCustomSorting(ascending, a, b, "is_available"),
    boolean__is_in_stock: (ascending: boolean) => (a: Profile, b: Profile) =>
      booleanCustomSorting(ascending, a, b, "is_in_stock"),
  },
  get filterAlgorithm() {
    return {
      ...generateFilterAlgorithm(this.filterable, "boolean"),
      material(row: Profile, query: string) {
        const str = (row.material?.name || "").toLowerCase()
        return str.includes(query.toLowerCase())
      },
      thickness(row: Profile, query: string) {
        const str = convertUnit({ value: row.thickness, conversionRate: 1 })
          .toFixed(2)
          .toLowerCase()
        return str.includes(query.toLowerCase())
      },
      length(row: Profile, query: string) {
        const str = convertUnit({ value: row.length, conversionRate: 1 })
          .toFixed(2)
          .toLowerCase()
        return str.includes(query.toLowerCase())
      },
      cost(row: Profile, query: string) {
        const str = convertUnit({ value: row.cost, conversionRate: 1 })
          .toFixed(2)
          .toLowerCase()
        return str.includes(query.toLowerCase())
      },
    }
  },
  get listColumns() {
    return getListColumns(this.filterable)
  },
  customFilters: [
    {
      name: "all",
      callback(row: Profile, query: string) {
        return [
          row.name || "",
          row.material?.name || "",
          convertUnit({ value: row.thickness, conversionRate: 1 }).toFixed(2),
          convertUnit({ value: row.length, conversionRate: 1 }).toFixed(2),
          convertUnit({ value: row.cost, conversionRate: 1 }).toFixed(2),
          row.is_available,
          row.is_in_stock,
          row.type,
          row.stock,
        ]
          .join("###")
          .toLowerCase()
          .includes(query.toLowerCase())
      },
    },
  ],
}

const fieldShapes = {
  field0: ["ROUND", "SQUARE", "RECTANGULAR", "OTHER"],
  field1: ["SQUARE", "RECTANGULAR", "OTHER"],
  field2: ["SQUARE", "RECTANGULAR", "OTHER"],
  field3: ["RECTANGULAR", "OTHER"],
}
const makeForm = (data?: Profile) => ({
  id: "profiles-page-form",
  fields: {
    id: {
      type: "hidden",
      defaultValue: data?.id,
    },
    material: {
      type: "multiselect",
      label: i18n.t("material"),
      placeholder: i18n.t("select_or_start_typing"),
      defaultValue: data?.material,
      options: materialStore.all,
      selectOptions: {
        multiple: false,
        trackBy: "id",
        label: "name",
        hideSelected: true,
        searchable: true,
        selectLabel: i18n.t("press_enter_select"),
        selectedLabel: i18n.t("selected"),
        deselectLabel: i18n.t("press_enter_remove"),
        closeOnSelect: true,
        openDirection: "bottom",
      },
      validations: ["required"],
    },
    name: {
      type: "text",
      label: i18n.t("name"),
      defaultValue: data?.name,
      validations: ["required"],
    },
    thickness: {
      type: "number",
      label: i18n.t("thickness_mm"),
      defaultValue: data?.thickness,
      validations: ["required"],
    },
    length: {
      type: "number",
      label: i18n.t("length_mm"),
      defaultValue: data?.length,
      validations: ["required"],
    },
    type: {
      type: "select",
      label: i18n.t("shape"),
      defaultValue: data?.type,
      options: [
        {
          label: `<${i18n.t("nothing")} ${i18n.t("selected")}}>`,
          value: "",
          disabled: true,
        },
        { value: "ROUND", label: i18n.t("round") },
        { value: "SQUARE", label: i18n.t("square") },
        { value: "RECTANGULAR", label: i18n.t("rectangular") },
        { value: "OTHER", label: i18n.t("other") },
      ],
      validations: ["required"],
      reloadOnChange: true,
    },
    field0: {
      type: "number",
      label: i18n.t("outer_radius"),
      defaultValue: data?.parameters ? data.parameters[0] : null,
      visible: !!(data?.type && fieldShapes.field0.includes(data?.type)),
    },
    field1: {
      type: "number",
      label: i18n.t("inner_radius"),
      defaultValue: data?.parameters ? data.parameters[1] : null,
      visible: !!(data?.type && fieldShapes.field1.includes(data?.type)),
    },
    field2: {
      type: "number",
      label: i18n.t("width"),
      defaultValue: data?.parameters ? data.parameters[2] : null,
      visible: !!(data?.type && fieldShapes.field2.includes(data?.type)),
    },
    field3: {
      type: "number",
      label: i18n.t("height"),
      defaultValue: data?.parameters ? data.parameters[3] : null,
      visible: !!(data?.type && fieldShapes.field3.includes(data?.type)),
    },
    cost: {
      type: "number",
      label: i18n.t("cost_e_m_unit"),
      defaultValue: data?.cost,
      validations: ["required"],
    },
    stock: {
      type: "number",
      label: i18n.t("stock"),
      defaultValue: data?.stock,
      validations: ["required"],
    },
    is_available: {
      type: "checkbox",
      defaultValue: data?.is_available,
      label: i18n.t("is_available"),
    },
  },
  valueProcessor: values => {
    const { field0, field1, field2, field3, ...valueWithoutField } = values
    const formattedValues: Profile = {
      ...valueWithoutField,
      parameters: [0, 0, 0, 0],
    }

    for (let i = 0; i < 4; i++) {
      formattedValues.parameters[i] = Object.prototype.hasOwnProperty.call(
        values,
        `field${i}`
      )
        ? values[`field${i}`]
        : 0
    }

    return formattedValues
  },
})

const mergeOptions = computed(() => ({
  whitelist: [
    "id",
    "name",
    "material",
    "thickness",
    "length",
    "cost",
    "type",
    "stock",
    "parameters",
    "is_available",
  ],
  schema: {
    id: {
      type: "integer",
    },
    name: {
      type: "string",
    },
    material: {
      type: "namedObject",
      options: materialStore.all,
    },
    thickness: {
      type: "number",
    },
    length: {
      type: "number",
    },
    cost: {
      type: "number",
    },
    stock: {
      type: "integer",
    },
    type: {
      type: "enum",
      options: fieldShapes.field0, // all shapes
    },
    outer_radius: {
      type: "number",
    },
    inner_radius: {
      type: "number",
    },
    width: {
      type: "number",
    },
    height: {
      type: "number",
    },
    is_available: {
      type: "boolean",
    },
  } as MergerSchema,
  dataProcessor: (data: Profile[]) =>
    data.map(d => ({
      ...d,
      outer_radius: d.parameters[0],
      inner_radius: fieldShapes.field1.includes(d.type) ? d.parameters[1] : null,
      width: fieldShapes.field2.includes(d.type) ? d.parameters[2] : null,
      height: fieldShapes.field3.includes(d.type) ? d.parameters[3] : null,
    })) as ProcessedProfile[],
  customValidation: (values: any, errors: any) => {
    const keys = [
      {
        name: "outer_radius",
        mapper: "field0",
      },
      {
        name: "inner_radius",
        mapper: "field1",
      },
      {
        name: "width",
        mapper: "field2",
      },
      {
        name: "height",
        mapper: "field3",
      },
    ]
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (!fieldShapes[key.mapper].includes(values.type)) {
        if (values[key.name])
          errors[key.name] = i18n.t("variable_shape_not_need_key_value", {
            shape: values.type,
            key: i18n.t(key.name).toLowerCase(),
          })
      } else if (!values[key.name]) {
        errors[key.name] = i18n.t("variable_shape_needs_key_value", {
          shape: values.type,
          key: i18n.t(key.name).toLowerCase(),
        })
      }
    }
    return { errors, values }
  },
  valueProcessor: (values: ProcessedProfile[]) =>
    values.map(v => ({
      ...v,
      parameters: [v.outer_radius || 0, v.inner_radius || 0, v.width || 0, v.height || 0],
    })),
}))

onMounted(() => {
  if (!requestStore.isRequestExist(profileStore.url)) profileStore.fetchAll()
})
</script>
