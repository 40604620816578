<template>
  <div class="animated fadeIn wrapped-with-pmpyfoncyt">
    <VTables
      v-if="page === 0"
      :title="$t('nestings')"
      :subtitle="$t('order_nesting')"
      :columns="tableColumns"
      :data="nestingStore.all"
      :make-form="() => ({})"
      :table-options="tableOptions"
    >
      <template #filters>
        <CCol :sm="4" class="d-flex flex-column align-items-end">
          <WrappedDatepicker
            v-model="from_date"
            class="dateInput"
            :format="date => dateFormat(date, 'dd MMM yyyy')"
            auto-apply
            close-on-auto-apply
            :enable-time-picker="false"
            position="left"
            :max-date="to_date"
            :placeholder="$t('from_date')"
          />
          <WrappedDatepicker
            v-model="to_date"
            class="dateInput"
            :format="date => dateFormat(date, 'dd MMM yyyy')"
            auto-apply
            close-on-auto-apply
            :enable-time-picker="false"
            position="left"
            :max-date="from_date"
            :placeholder="$t('from_date')"
          />
        </CCol>
      </template>
      <template #select="data">
        <div style="min-width: 20px">
          <i
            v-if="!isSelected(data.row.id)"
            class="fa fa-square-o"
            @click="toggle(data.row.id)"
          />
          <i
            v-if="isSelected(data.row.id)"
            class="fa fa-check-square-o"
            @click="toggle(data.row.id)"
          />
        </div>
      </template>
    </VTables>

    <CCard v-if="page == 1">
      <CCardBody>
        <CRow>
          <CCol :sm="8" data-toggle="aside-menu-show">
            <h4 id="traffic" class="card-title mb-0">{{ title }}</h4>
            <div class="small text-muted">{{ subtitle }}</div>
          </CCol>
        </CRow>
        <CRow v-if="!loaded" style="margin-top: 20px; margin-bottom: 20px">
          <CCol>
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status" />
            </div>
          </CCol>
        </CRow>
        <v-client-table
          v-if="loaded"
          ref="client-table"
          :columns="stock_columns"
          :data="stock_options"
          :options="options"
        >
          <template #stock="data">
            <b>{{ getMaterial(getStock(data.row.id).material).name }}</b>

            <div v-if="!getStock(data.row.id).section" class="small text-muted">
              {{ getStock(data.row.id).thickness.toFixed(2) }} mm
            </div>
            <div v-if="getStock(data.row.id).section" class="small text-muted">
              {{ getStock(data.row.id).hash }}
            </div>
          </template>

          <template #parts="data">
            {{ data.row.batches.length }}
          </template>

          <template #pieces="data">
            {{ data.row.parts }}
          </template>
          <template #total_parts="{ row }">
            <span>{{ (row.batches || []).length }}</span>
          </template>
          <template #actions="data">
            <CButton
              v-if="!submitted.includes(data.row.id)"
              class="btn-space btn-sm"
              color="primary"
              @click.stop="addNesting(data.row.id)"
            >
              <strong>Nest</strong>
            </CButton>

            <CButton
              v-if="submitted.includes(data.row.id)"
              class="btn-space btn-sm"
              color="primary"
              disabled
              @click.stop="addNesting(data.row.id)"
            >
              <strong>In queue</strong>
            </CButton>
          </template>
        </v-client-table>
      </CCardBody>
    </CCard>

    <CButton
      v-if="page != 0"
      class="btn"
      color="secondary"
      style="margin-bottom: 25px; float: left"
      @click.stop="decrementPage"
    >
      <i class="fa fa-chevron-left" />
      {{ $t("back") }}
    </CButton>

    <CButton
      v-if="page == 0"
      class="btn"
      color="primary"
      style="margin-bottom: 25px; float: right"
      :disabled="selected.length == 0"
      @click.stop="getBatches"
    >
      {{ $t("next") }}
      <i class="fa fa-chevron-right" />
    </CButton>
  </div>
</template>

<script lang="ts" setup>
/**
 * TODO: Refactor this once the team wants to add Nestings page
 */

import type { VTableOptions } from "@/components/VTables.vue"
import { ref, onMounted, computed } from "vue-demi"
import { useMeta } from "vue-meta"
import { useI18n } from "vue-i18n"
import { manuStore, nestingStore } from "@/store"
import type { Nesting } from "@/interfaces"
import VTables from "@/components/VTables.vue"
import {
  dateFilterAlgorithm,
  dateToFilterString,
  getDeliveryType,
  dateFormat
} from "@/libraries/helpers"
import WrappedDatepicker from "@/components/WrappedDatepicker.vue"

const i18n = useI18n()

useMeta({ title: i18n.t("new_nestings") })

const from_date = ref(new Date())
const to_date = ref(null)
const page = ref(0)
const opened = ref([])
const selected = ref([])
const submitted = ref([])
const loaded = ref(false)
const batches = ref([])

const stock_columns = ref(["id", "stock", "parts", "pieces", "actions"])

const tableColumns = [
  "select",
  "date__created",
  "date__updated",
  "customer",
  "reference",
  "order_id",
  "status",
  "date__delivery_date",
  "delivery_type",
  "total_parts",
  "total_amount",
]

const tableOptions: VTableOptions = {
  headings: {
    select: "",
    date__created: i18n.t("added"),
    customer: i18n.t("customer"),
    order_id: i18n.t("order_no"),
    total_parts: i18n.t("total_parts"),
    date__delivery_date: i18n.t("delivery_date_small"),
    delivery_type: i18n.t("delivery_type_small"),
    total_amount: i18n.t("total"),
    date__updated: i18n.t("updated"),
  },
  sortable: [
    "date__created",
    "date__updated",
    "reference",
    "status",
    "date__delivery_date",
    "delivery_type",
    "total_parts",
    "total_amount",
    "customer",
    "order_id",
  ],
  filterable: [
    "date__created",
    "date__updated",
    "date__delivery_date",
    "customer",
    "order_id",
    "total_parts",
    "delivery_type",
    "total_amount",
    "reference",
    "status",
  ],
  filterAlgorithm: {
    date__created: (row: Nesting, query: string) =>
      dateFilterAlgorithm(row, query, "created"),
    date__updated: (row: Nesting, query: string) =>
      dateFilterAlgorithm(row, query, "updated"),
    date__delivery_date: (row: Nesting, query: string) =>
      dateFilterAlgorithm(row, query, "delivery_date"),
  },
  customFilters: [
    {
      name: "all",
      callback(row, query) {
        return [
          dateToFilterString(row, "created"),
          dateToFilterString(row, "created"),
          row.customer || "",
          row.reference || "",
          row.order_id || "",
          row.status || "",
          dateToFilterString(row, "delivery_date"),
          i18n.t(getDeliveryType(row.delivery_type).toLowerCase()).toLowerCase(),
          (row.batches || []).length,
          row.total_amount || "",
        ]
          .join("###")
          .toLowerCase()
          .includes(query.toLowerCase())
      },
    },
  ],
}

const stock_options = computed(() => {
  const stock_options = []

  if (loaded.value) {
    for (let i = 0; i < batches.value.length; i++) {
      var batch = batches.value[i]
      const index = stock_options.findIndex(x => x.id == batch.stock)

      if (index == -1) {
        const stock_option = {
          id: batch.stock,
          parts: batch.quantity,
          batches: [batch],
        }
        stock_options.push(stock_option)
      } else {
        stock_options[index].parts += batch.quantity
        stock_options[index].batches.push(batch)
      }
    }
  }

  return stock_options
})

// Get stock by it's id
function getStock(id) {
  let index = manuStore.sheets.findIndex(x => x.id == id)
  if (index >= 0) {
    return manuStore.sheets[index]
  }

  index = manuStore.profiles.findIndex(x => x.id == id)
  if (index >= 0) {
    return manuStore.profiles[index]
  }

  return {}
}

// Get material by it's id
function getMaterial(id) {
  const index = manuStore.materials.findIndex(x => x.id == id)
  if (index >= 0) {
    return manuStore.materials[index]
  }

  return {}
}

function incrementPage() {
  page.value += 1
}

function decrementPage() {
  page.value -= 1
}

function isSelected(id) {
  const index = selected.value.findIndex(x => x == id)
  return index != -1
}

function toggle(id) {
  const index = selected.value.findIndex(x => x == id)
  if (index == -1) {
    selected.value.push(id)
  } else {
    selected.value.splice(index, 1)
  }
}

function getBatches() {
  batches.value = []
  incrementPage()

  // for (var i = 0; i < selected.length; i++) {
  //   var order_id = selected[i]

  //   this.$store.dispatch('API_GET', '/v1/orders/' + order_id + "/batches")
  //   .then(response => {
  //     for (var j = 0; j < response.data.length; j++) {
  //       var batch = response.data[j]
  //       this.batches.push(batch)
  //     }
  //     this.loaded = true
  //   })
  //   .catch(error => {
  //     this.$notify({
  //       type: "error",
  //       title: i18n.t('failed'),
  //       text: i18n.t('failed_alert')
  //     })
  //   })
  // }
}

function addNesting(id) {
  console.log("add nesting", id)
  // var index = stock_options.findIndex(x => x.id == id);
  // var batch_ids = stock_options[index].batches.map(x => x.id)
  // var nesting = {
  //   "batches": batch_ids,
  //   "orders": [],
  //   "stock": id,
  //   "timeout": 500
  // }

  // this.$store.dispatch('API_POST', {
  //   url:'/v1/nestings',
  //   data: nesting
  // })
  // .then(response => {
  //   this.submitted.push(id)
  // })
  // .catch(error => {
  //   this.$notify({
  //     type: "error",
  //     title: i18n.t('failed'),
  //     text: i18n.t('failed_alert')
  //   })
  // })
}

onMounted(() => {
  console.log("mounted")

  // this.$root.$on('openAddModal'+this.title, () => {
  //   this.$router.push("/orders/new");
  // });

  // // Retrieve manufacturer data on mount
  // this.$store.dispatch("MANU_REQUEST")
  // .then(resp => this.$store.dispatch("MANU_DATA", resp.id))
})
</script>

<style lang="scss">
.wrapped-with-pmpyfoncyt {
  .dateInput {
    width: 300px !important;
    max-width: 300px !important;
  }
}
</style>
