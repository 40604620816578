<template>
  <v-client-table
    ref="tableElement"
    :columns="props.columns"
    :data="batches"
    :options="tableOptions"
    @select="selectedBatchIds = $event.map(b => b.id)"
    @pagination="calculateTotalVisibleChildren"
    @filter="$nextTick(calculateTotalVisibleChildren)"
    @loaded="handleTableLoaded"
  >
    <template v-if="!isValid" #afterFilters>
      <tr class="VueTables__no-results">
        <td class="text-center" :colspan="props.columns.length + 1">
          <div class="text-danger small">
            <b>{{ $t("invalid_parts_text") }}</b>
          </div>
        </td>
      </tr>
    </template>
    <template #prependBody>
      <tr
        v-for="(file, index) of failedFiles"
        :key="index"
        class="VueTables__row"
      >
        <td :colspan="props.columns.length + 1">
          <div class="d-flex align-items-center">
            <span
              class="text-danger white-space__nowrap"
              v-html="$t('failed_to_process_filename', { filename: `${file.filename}`, error: `${file.error}` })"
            />
            <div class="d-flex align-items-center ms-4">
              <CButton
                color="danger"
                variant="outline" 
                size="sm"
                @click="$emit('remove-failed-file', file)"
              >
                {{ $t("remove") }}
              </CButton>
            </div>
          </div>
        </td>
      </tr>
      <tr
        v-for="file of duplicatedFiles"
        :key="file.id"
        class="VueTables__row"
      >
        <td :colspan="props.columns.length + 1">
          <div class="d-flex align-items-center">
            <span
              class="text-danger white-space__nowrap"
              v-html="$t('file_duplicated', { filename: `${file.filename}` })"
            />
            <div class="d-flex align-items-center ms-4">
              <CButton
                color="secondary"
                variant="outline" 
                size="sm"
                @click="$emit('process-duplicate', file)"
              >
                {{ $t("proceed") }}
              </CButton>
              <CButton
                class="ms-2"
                color="danger"
                variant="outline" 
                size="sm"
                @click="$emit('remove-failed-file', file)"
              >
                {{ $t("remove") }}
              </CButton>
            </div>
          </div>
        </td>
      </tr>
      <tr
        v-for="(file, index) of inProgressFiles"
        :key="index"
        class="VueTables__row"
      >
        <td :colspan="props.columns.length + 1">
          <div class="d-flex flex-column">
            <span
              class="fw-bold white-space__nowrap"
            >
              {{ file.filename }}
            </span>
            <div class="d-flex align-items-center my-2">
              <CProgress
                class="w-100"
              >
                <CProgressBar 
                  color="primary"
                  variant="striped"
                  animated
                  :value="file.progress"
                >
                  {{ file.progress || 0 }}%
                </CProgressBar>
              </CProgress>
            </div>
          </div>
        </td>
      </tr> 
    </template>

    <template #__group_meta="{ row }">
      ASSEMBLY {{ $t("filename") }}: {{ row.part?.filename }}, CAD
      {{ $t("name") }}: {{ row.part?.name }}
    </template>
    <template #index="{ index, column, row }">
      <div
        class="d-flex h-100 align-items-center"
      >
        <TableTreeExpander
          v-show="props.treeView"
          class="me-3"
          :is-expanded="!hideChildOfBatchIds.includes(row.id)"
          :isChild="!!row.parent"
          :deep="row.treeDeep"
          :total-children="row.childrenIds.length"
          :total-visible-children="totalVisibleChildren[row.id]"
          @expand="hideChildOfBatchIds = hideChildOfBatchIds.filter(id => id !== row.id)"
          @collapse="hideBatchId(row.id)"
        />
        <span :ref="column + index.toString()">
          {{ index }}
        </span>
      </div>
    </template>

    <template #parent="{ row }">
      <span>{{ row.parent }}</span>
    </template>

    <template #filename="{ row }">
      <div class="d-flex">
        <b class="d-flex align-items-center">
          {{ row.part?.filename }}
        </b>
      </div>
    </template>

    <template #preview="{ row }">
      <div
        style="width: 50px; height: 50px"
      >
        <PartImage
          v-if="!row.isTemporary"
          :batch="row"
          @click.stop=""
        />
        <CSpinner v-else />
      </div>
    </template>

    <template #name="{ row }">
      <b>
        {{ row.part?.name || row.part?.id }}
      </b>
      <div
        class="text-muted small white-space__nowrap"
        style="cursor: pointer"
        @click.stop="copyTextToClipboard(row.id, `${row.id} ${$t('copied_to_clipboard')}`)"
      >
        <i class="fa fa-copy" />
        {{ row.id }}
      </div>
    </template>

    <template #file="{ row }">
      {{ row.part?.name }}
    </template>

    <template #material="{ row }">
      <div style="width: 250px">
        <ImprovedVueMultiselect
          :id="`material-${row.id}`"
          v-model="row.injected_material"
          track-by="id"
          :options="row.options.materials"
          label="name"
          :multiple="false"
          :show-labels="true"
          :placeholder="
            row.options.materials.length
              ? $t('select_or_start_typing')
              : $t('no_material_available')
          "
          :max-height="200"
          open-direction="bottom"
          :disabled="!row.options.materials.length"
          :scrollable-container-selector="MULTISELECT_CONTAINER"
          :calculate-left="getMultiselectLeft"
          :calculate-top="getMultiselectTop"
          @update:model-value="props.updateStock($event || { type: 'material', id: null }, row.id)"
        />
      </div>
    </template>

    <template #thickness="{ row }">
      <div style="width: 250px" @click.stop>
        <ImprovedVueMultiselect
          :id="`thickness-${row.id}`"
          v-model="row.injected_thickness"
          track-by="value"
          :options="row.options.thicknesses"
          label="name"
          :multiple="false"
          :show-labels="true"
          :placeholder="row.options.thicknesses.length ? $t('select_or_start_typing') : 'N/A'"
          :max-height="200"
          open-direction="bottom"
          :disabled="!row.options.thicknesses.length"
          :scrollable-container-selector="MULTISELECT_CONTAINER"
          :calculate-left="getMultiselectLeft"
          :calculate-top="getMultiselectTop"
          @update:model-value="props.updateStock($event || { type: 'size', value: null }, row.id)"
        />
      </div>
    </template>

    <template #process="{ row }">
      <div style="width: 250px" @click.stop>
        <ImprovedVueMultiselect
          :id="`process-${row.id}`"
          v-model="row.process"
          track-by="id"
          :options="row.options.processes"
          label="name"
          :multiple="false"
          :show-labels="true"
          :placeholder="
            row.options.processes.length
              ? $t('select_or_start_typing')
              : $t('no_process_available')
          "
          :max-height="200"
          :disabled="!row.options.processes.length"
          open-direction="bottom"
          :allow-empty="false"
          :scrollable-container-selector="MULTISELECT_CONTAINER"
          :calculate-left="getMultiselectLeft"
          :calculate-top="getMultiselectTop"
          @update:model-value="props.updateMachines($event, row.id)"
        />
      </div>
    </template>

    <template #operations="{ row }">
      <div
        v-if="row.options.operations && row.operations"
        style="width: 250px"
        @click.stop
      >
        <ImprovedVueMultiselect
          :id="`operations-${row.id}`"
          v-model="row.operations"
          track-by="id"
          :options="row.options.operations"
          label="name"
          :multiple="true"
          :show-labels="true"
          :placeholder="
            row.options.operations.length
              ? $t('select_or_start_typing')
              : $t('no_operations_available')
          "
          :max-height="200"
          open-direction="bottom"
          :disabled="!row.options.operations.length"
          :scrollable-container-selector="MULTISELECT_CONTAINER"
          :calculate-left="getMultiselectLeft"
          :calculate-top="getMultiselectTop"
          @update:model-value="props.updateMachines($event, row.id)"
        >
          <template #tag="props">
            <span
              v-show="
                props.option.is_visible || authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin
              "
              :key="props.index"
              class="multiselect__tag"
            >
              <span>{{ props.option.name }}</span>
            </span>
          </template>
        </ImprovedVueMultiselect>
      </div>
    </template>

    <template #certificates="{ row }">
      <div style="width: 250px" @click.stop>
        <ImprovedVueMultiselect
          :id="`certificates-${row.id}`"
          key="certificates"
          v-model="row.certificateOptions"
          :options="
            row.options.certificates.map(c => ({
              id: c,
              name: c.replace(/.*(\d)(\d)/, '$1.$2'),
            }))
          "
          track-by="id"
          label="name"
          :multiple="true"
          :show-labels="true"
          :placeholder="
            row.options.certificates.length
              ? $t('select_or_start_typing')
              : $t('no_certificates_available')
          "
          :max-height="200"
          open-direction="bottom"
          :scrollable-container-selector="MULTISELECT_CONTAINER"
          :calculate-left="getMultiselectLeft"
          :calculate-top="getMultiselectTop"
          :disabled="row.options.certificates.length === 0"
          @update:model-value="
            batchStore.update({
              id: row.id,
              certificates: $event.map(c => c.id),
            })
              .then(() => props.autoSelectOption([row.id]))
          "
        />
      </div>
    </template>

    <template #reference="data">
      <input
        v-if="data.row.part"
        :id="`reference-${data.row.id}`"
        :ref="data.column + data.index.toString()"
        v-model="data.row.part.reference"
        class="form-control"
        @click.stop
        @update:model-value="updateReference($event, data.row.part.id)"
      />
    </template>

    <template #boolean__is_valid="{ row }">
      <template v-if="!batchPriceLoading.includes(row.id)">
        <i
          v-if="row.is_valid == true"
          style="color: green"
          class="fa fa-check"
        />
        <i v-else style="color: red" class="fa fa-times" />
      </template>
      <CSpinner v-else />
    </template>

    <template #quantity="data">
      <div style="width: 80px">
        <input
          :id="`quantity-${data.row.id}`"
          :ref="data.column + data.index.toString()"
          v-model.number="data.row.quantity"
          type="number"
          class="form-control"
          min="1"
          @click.stop
          @update:model-value="updateQuantity($event, data.row.id)"
        />
      </div>
    </template>

    <template #price="{ row }">
      <div v-if="!batchPriceLoading.includes(row.id)">
        {{
          toCurrency(
            isNaN(row.unit_amount) || row.unit_amount == 0 ? 0 : row.unit_amount
          )
        }}
      </div>
      <CSpinner v-else />
    </template>

    <template #total="{ row }">
      <div v-if="!batchPriceLoading.includes(row.id)">
        {{
          toCurrency(
            isNaN(row.total_amount) || row.total_amount == 0
              ? 0
              : row.total_amount
          )
        }}
      </div>
      <CSpinner v-else />
    </template>

    <template #actions="{ row }">
      <CButton
        v-if="!row.isTemporary"
        @click.stop="deleteRow(row)"
      >
        <i class="fa fa-trash text-danger" />
      </CButton>
    </template>
    <template #appendBody>
      <tr
        v-show="props.loading"
        class="loading-placeholder-row"
      >
        <td class="text-center px-1 pt-2" colspan="3">
          <div class="loading-placeholder small" />
        </td>
        <td class="text-center px-1 pt-2">
          <div class="loading-placeholder small" />
          <div class="loading-placeholder small mt-1" />
        </td>
        <td class="text-center px-1 pt-2">
          <div class="loading-placeholder big" />
        </td>
        <td class="text-center px-1 pt-2" colspan="13">
          <div class="loading-placeholder" />
        </td>
      </tr>
      <tr class="table-body-filler" />
    </template>

    <template #afterBody>
      <MultiEditRow
        v-if="!!batchesSelected.length"
        :selected-batches="batchesSelected"
        :update-stock="props.updateStock"
        :update-machine="props.updateMachines"
        @deleted="() => selectedBatchIds = []"
      />
    </template>
  </v-client-table>
</template>

<script lang="ts" setup>
import { ref, computed, watch, type Ref, onUnmounted } from "vue-demi"
import { useI18n } from "vue-i18n"
import { debounce } from "lodash-es"
import sweetalert from "sweetalert2"
import { batchStore, partStore, globalStore, authStore } from "@/store"
import { copyTextToClipboard, getFilterEmitsOptions, sleep, toCurrency } from "@/libraries/helpers"
import { uploadFileStatus, type Batch } from '@/interfaces'
import { 
  treeHelpers, getTableOptions, MULTISELECT_CONTAINER, 
  getMultiselectLeft, getMultiselectTop
} from "../helpers"
import MultiEditRow from "./MultiEditRow.vue"
import ImprovedVueMultiselect from "@/components/ImprovedVueMultiselect.vue"
import PartImage from "@/components/part-image/PartImage.vue"
import TableTreeExpander from "@/components/TableTreeExpander.vue"

const i18n = useI18n()
const emit = defineEmits(["remove-failed-file", "process-duplicate"])

const props = withDefaults(
  defineProps<{
    orderId?: number
    columns: string[]
    loading?: boolean
    updateStock?: (data: Batch, id: number, do_patch?: boolean ) => void
    updateMachines?: (data: Batch, id: number) => void
    treeView?: boolean
    globalSearch?: string
    autoSelectOption?: Function
    files: any[]
  }>(),
  {
    loading: false,
    updateMachines: (data: Batch, id: number) => {},
    updateStock: (data: Batch, id: number, do_patch?: boolean) => {},
    treeView: false,
    globalSearch: "",
    autoSelectOption: () => {},
    files: () => []
  }
)

const tableElement = ref<Ref>(null)

watch (() => props.treeView, () => hideChildOfBatchIds.value = [])
watch (() => props.globalSearch, () => {
  if (tableElement.value) (tableElement.value as any).setCustomFilters({ all: props.globalSearch })
})


const hideChildOfBatchIds = ref([])
const hideBatchId = (id: number) => {
  hideChildOfBatchIds.value = treeHelpers.hideBatchId(id, batches.value, hideChildOfBatchIds.value)
}
const totalVisibleChildren = ref({})
const calculateTotalVisibleChildren = () => {
  totalVisibleChildren.value = treeHelpers.calculateTotalVisibleChildren(batches.value, totalVisibleChildren.value)
}
watch(hideChildOfBatchIds,
  async () => {
    treeHelpers.handleBatchTree(batches.value, hideChildOfBatchIds.value)
    await sleep(100)
    calculateTotalVisibleChildren()
  },
  { deep: true }
)

const batches = computed(() => batchStore.getBatchesByOrderId(props.orderId))
watch(batches,
  async () => {
    treeHelpers.handleBatchTree(batches.value, hideChildOfBatchIds.value)
    await sleep(100)
    calculateTotalVisibleChildren()
  },
  { deep: true }
)
const isValid = computed(() => batches.value.every(batch => !!batch.is_valid))
const selectedBatchIds = ref([])
const batchesSelected = computed(() => batches.value.filter(b => selectedBatchIds.value.includes(b.id)))
const validBatches = computed(() => batchStore.all.filter(b => b.injected_material && b.injected_thickness).map(b => b.id))
const batchPriceLoading = computed(() => {
  const ids = batchStore.updateRequests.queue.map(q => q.id)
  return validBatches.value.filter(id => ids.includes(id))
})

const updateQuantity = debounce((quantity, id) => batchStore.update({ id, quantity }, {}), 1000)
const updateReference = debounce((reference, id) => partStore.update({ id, reference }), 1000)

const failedFiles = computed(() => props.files.filter((f: any) => f.status === uploadFileStatus.ERROR))
const inProgressFiles = computed(() => props.files.filter((f: any) => [uploadFileStatus.UPLOADING, uploadFileStatus.PROCESSING].includes(f.status)))
const duplicatedFiles = computed(() => props.files.filter((f: any) => f.status === uploadFileStatus.DUPLICATED))

const tableOptions = computed(() => getTableOptions({
  translator: i18n.t,
  columns: props.columns as string[],
  loading: props.loading,
  treeView: props.treeView,
  batches: batches.value
}))

const handleTableLoaded = () => {
  if (tableElement.value) {
    (tableElement.value as any).$.emitsOptions = {
      ...(tableElement.value as any).$.emitsOptions,
      ...getFilterEmitsOptions(tableOptions.value.filterable || []),
    }

    const totalData = ((tableElement.value as any).allFilteredData || []).length
    let perPage = ((tableElement.value as any).$refs?.table?.page || 1) * (tableElement.value as any).options.perPage
    if (perPage > totalData) perPage = totalData
    globalStore.changeFooter({
      text: `Showing 1 to ${perPage} of ${totalData} records`,
      showPagination: false
    })
  }
}

const deleteRow = (batch: Batch) => {
  sweetalert
    .fire({
      title: i18n.t("remove"),
      text: i18n.t("delete_row_body"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: i18n.t("delete"),
      cancelButtonText: i18n.t("cancel"),
    })
    .then(result => {
      if (result.isConfirmed) {
        batchStore.remove(batch.id)
      }
    })
    .catch()
}

onUnmounted(() => {
  globalStore.reset(["footer"])
})
</script>
