<template>
  <div class="animated fadeIn">
    <VTables
      :title="$t('raw_materials')"
      :subtitle="$t('all') + ' ' + $t('raw_materials')"
      :add-text="$t('add_raw_material')"
      :columns="tableColumns"
      :action-delete="authStore.authenticatedUser?.is_admin"
      :action-edit="authStore.authenticatedUser?.is_admin"
      :show-add-button="authStore.authenticatedUser?.is_admin"
      :make-form="makeForm"
      :merge-options="mergeOptions"
      :show-merge-options="true"
      :table-options="tableOptions"
      :data="materialStore.all"
      :add="materialStore.add"
      :update="materialStore.update"
      :remove="({ id }) => materialStore.remove(id)"
      :default-sort="{ column: 'name', ascending: true }"
      @merge-done="materialStore.modifyData"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { useMeta } from "vue-meta"
import type { Material, MergerSchema } from "@/interfaces"
import { materialTypeOptions } from "@/interfaces"
import { materialStore, authStore } from "@/store"
import VTables, { type VTableOptions } from "@/components/VTables.vue"

const i18n = useI18n()
useMeta({ title: i18n.t("raw_materials") })

const tableColumns = ["name", "code", "description", "density", "type", "actions"]
const tableOptions: VTableOptions = {
  sortable: ["name", "code", "description", "density", "type"],
  headings: {
    name: i18n.t("name"),
    actions: i18n.t("actions"),
    density: i18n.t("density"),
    description: i18n.t("description"),
    code: i18n.t("code"),
    type: i18n.t("type"),
  },
  filterable: ["name", "code", "description", "density", "type"],
  customFilters: [
    {
      name: "all",
      callback(row: Material, query: string) {
        return [
          row.name || "",
          row.code || "",
          row.description || "",
          row.density || "",
          row.type || "",
          row.type || "",
        ]
          .join("###")
          .toLowerCase()
          .includes(query.toLowerCase())
      },
    },
  ],
}

const makeForm = (data?: Material) => ({
  id: "raw-materials-page-form",
  fields: {
    id: {
      type: "hidden",
      defaultValue: data?.id,
    },
    type: {
      type: "select",
      label: i18n.t("select_category"),
      defaultValue: data?.type,
      validations: ["required"],
      options: [
        {
          label: `<${i18n.t("nothing")} ${i18n.t("selected")}}>`,
          value: "",
          disabled: true,
        },
        ...materialTypeOptions.map(v => ({
          label: v,
          value: v,
          disabled: false,
        })),
      ],
    },
    name: {
      type: "text",
      label: i18n.t("name"),
      placeholder: "",
      validations: ["required"],
      defaultValue: data?.name,
    },
    description: {
      type: "text",
      label: i18n.t("description"),
      validations: ["required"],
      defaultValue: data?.description,
    },
    code: {
      type: "text",
      label: i18n.t("code"),
      defaultValue: data?.code,
      validations: ["required"],
    },
    density: {
      type: "number",
      label: i18n.t("density"),
      validations: ["required"],
      defaultValue: data?.density,
    },
  },
})

const mergeOptions = {
  whitelist: ["id", "code", "name", "description", "density", "type"],
  schema: {
    id: {
      type: "integer",
    },
    code: {
      type: "string",
    },
    name: {
      type: "string",
    },
    density: {
      type: "number",
    },
    type: {
      type: "enum",
      options: [...materialTypeOptions],
    },
  } as MergerSchema,
}
</script>
